import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OpenNaventService from '@/services/OpenNaventService';

const handler = (name, asyncFunction) =>
  createAsyncThunk(`openNavent/${name}`, async (arg) => {
    const data = await asyncFunction(arg);
    return data;
  });

export const getInfoForContact = handler(
  'getInfoForContact',
  OpenNaventService.getInfoForContact
);

export const contactRealState = handler(
  'contactRealState',
  OpenNaventService.contactRealState
);

const initialState = {
  property: { companyLogo: '', createdBy: false, isTokkoUser: false },
  user: { email: false, fullName: false, phone: false },
  portalLogo: '',
  dataEdited: false,
  loadingData: false,
  loadingSend: false,
  sent: false,
};

export const OpenNaventSlice = createSlice({
  name: 'openNavent',
  initialState,
  reducers: {
    onChangeUser(state, actions) {
      state.user = {
        ...state.user,
        [actions.payload.name]: actions.payload.value,
      };
    },

    toggleSent(state) {
      state.sent = !state.sent;
    },

    resetForm(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfoForContact.pending, (state) => {
        state.loadingData = true;
      })
      .addCase(getInfoForContact.fulfilled, (state, action) => {
        state.loadingData = false;

        const { property, user, portal_icon } = action.payload;
        const { company_logo, is_tokko_user, created_by } = property;
        const { email, full_name, phone } = user;

        state.property = {
          companyLogo: company_logo,
          createdBy: created_by,
          isTokkoUser: is_tokko_user,
        };

        state.portalLogo = portal_icon;

        state.user = { email, fullName: full_name, phone, message: '' };
      });

    builder
      .addCase(contactRealState.pending, (state) => {
        state.loadingSend = true;
      })
      .addCase(contactRealState.fulfilled, (state) => {
        state.loadingSend = false;
        state.sent = true;
      });
  },
});

export const { onChangeUser, resetForm, toggleSent } = OpenNaventSlice.actions;

export default OpenNaventSlice.reducer;
