import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from '@/services/UserService';

export const updateUserDataAsync = createAsyncThunk(
  'user-data/updateUserDataAsync',
  async () => {
    const data = await UserService.getUserMeData();
    return data;
  }
);

const initialState = {
  data: false,
};

export const UserSlice = createSlice({
  name: 'user-data',
  initialState,
  reducers: {
    setUserState(state, action) {
      state = action.payload.user_data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserDataAsync.fulfilled, (state, action) => {
      state.data = { ...action.payload.user_data };
    });
  },
});

export const { setUserState } = UserSlice.actions;

export const selectUserState = (state) => state.UserSlice;

export default UserSlice.reducer;
