export const initialState = {
  isSidebarOpen: true,
  isRightbarOpen: false,
  showModalMessenger: false,
};

export function AppReducer(state, action) {
  switch (action.type) {
    case 'init_stored': {
      return action.value;
    }
    case 'update_sidebar': {
      return {
        ...state,
        isSidebarOpen: action.value,
      };
    }
    case 'update_rightbar': {
      return {
        ...state,
        isRightbarOpen: action.value,
      };
    }
    case 'update_show_modal_messenger': {
      return {
        ...state,
        showModalMessenger: action.value,
      };
    }
    default:
      // eslint-disable-next-line no-console
      console.warn(`No existe la opcion en el local storage`);
  }
}
