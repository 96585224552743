/* eslint-disable import/prefer-default-export */

export const tokkoModalActions = (action, host) => {
  if (
    !window.location.origin.includes(host) &&
    window.location.href.includes('outsidelayout')
  ) {
    window.parent.postMessage(action, host);
  }
};
