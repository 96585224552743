const DictionaryPermissions = {
  '/properties/propertyimporter': ['properties', 'can_view_property_importer'],
  '/properties/propertyimporter/importreport': [
    'properties',
    'can_view_property_importer',
  ],
  // '/outsidelayout/calendar': 'isCompanyAdmin',
  // '/outsidelayout/chat': ['waffle_flags', 'tokko_messenger.talk2executive'],
  '/marketing/webcontact': 'marketing',
  '/portals/portals': ['marketing', 'can_publish_in_portals'],
  '/marketing/carrousel': 'marketing',
};

export default DictionaryPermissions;
