import ApiService from '@/services/ApiService';

class FavoritesService {
  static async getFavSearchs() {
    const composedURL = `/api3/favourites/searches`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getFavProperties() {
    const composedURL = `/api3/favourites/properties`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getFavContacts() {
    const composedURL = `/api3/favourites/contacts`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getFavDevelopments() {
    const composedURL = `/api3/favourites/developments`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getDataForUnifyContacts(ids) {
    // eslint-disable-next-line no-unused-vars
    const composedURL = `/api3/favourites/developments${ids}`;
    // const response = await ApiService.get(composedURL);
    const response = {
      unify_form_data: {
        status: 'verify',
        conflicts: [
          {
            text: 'AGENTE',
            values: [
              { id: 1, field: '1', value: 'Admin Account' },
              { id: 2, field: '2', value: 'Maru Solimano' },
            ],
          },
          {
            text: 'NOMBRE',
            values: [
              { id: 1, field: '1', value: 'Eugenio' },
              { id: 2, field: '2', value: 'Mercedes Marti' },
            ],
          },
        ],
      },
    };
    return response;
  }

  static async updateUnifyContacts(ids) {
    // eslint-disable-next-line no-unused-vars
    const composedURL = `/api3/favourites/developments${ids}`;
    // const response = await ApiService.get(composedURL);
    const response = {
      status: 'success',
    };
    return response;
  }

  static async deleteFavSearch(id) {
    const composedURL = `/api3/favourites/searches/${id}`;
    const response = await ApiService.delete(composedURL);
    return response;
  }

  static async deleteFavProperties(id) {
    const composedURL = `/api3/favourites/properties/${id}`;
    const response = await ApiService.delete(composedURL);
    return response;
  }

  static async deleteFavContacts(id) {
    const composedURL = `/api3/favourites/contacts/${id}`;
    const response = await ApiService.delete(composedURL);
    return response;
  }

  static async deleteFavDevelopments(id) {
    const composedURL = `/api3/favourites/developments/${id}`;
    const response = await ApiService.delete(composedURL);
    return response;
  }
}

export default FavoritesService;
