/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import QuickDisplayService from '@/services/QuickDisplayService';
import ContactsService from '@/services/ContactsService';

const handler = (name, asyncFunction) =>
  createAsyncThunk(`quickDisplay/${name}`, async (arg) => {
    const data = await asyncFunction(arg);
    return data;
  });

export const getFichaInfoUrl = handler(
  'getFichaInfoUrl',
  QuickDisplayService.getFichaInfoUrl
);

export const getQuickPropData = handler(
  'getQuickPropData',
  QuickDisplayService.getQuickPropData
);

export const getQuickOpenNaventData = handler(
  'getQuickOpenNaventData',
  QuickDisplayService.getQuickOpenNaventData
);

export const getQuickDevData = handler(
  'getQuickDevData',
  QuickDisplayService.getQuickDevData
);

export const getHighLightContacts = handler(
  'getHighLightContacts',
  ContactsService.getContacts
);

export const getSents = handler('getSents', QuickDisplayService.getSents);

export const getDevelopmentSents = handler(
  'getDevelopmentSents',
  QuickDisplayService.getDevelopmentSents
);

export const getFiles = handler('getFiles', QuickDisplayService.getFiles);

export const getDevelopmentFiles = handler(
  'getDevelopmentFiles',
  QuickDisplayService.getDevelopmentFiles
);

export const starProperty = handler(
  'starProperty',
  QuickDisplayService.starProperty
);

export const starDevelopment = handler(
  'starDevelopment',
  QuickDisplayService.starDevelopment
);

export const toggleFavourite = handler(
  'toggleFavourite',
  QuickDisplayService.toggleFavourite
);

export const toggleFavouriteDevelopment = handler(
  'toggleFavouriteDevelopment',
  QuickDisplayService.toggleFavouriteDevelopment
);

export const isPropOrDevStarred = handler(
  'isPropOrDevStarred',
  QuickDisplayService.isPropOrDevStarred
);
