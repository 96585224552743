/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ErrorComponent from './ErrorComponent';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: error };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorComponent />;
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
