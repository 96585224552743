/* eslint-disable no-use-before-define */
/* eslint-disable no-promise-executor-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '@/services/UserService';

export const setUserPreferencesAsync = createAsyncThunk(
  'userPreferences/userPreferencesAsync',
  async () => {
    const data = await UserService.getConfigSetting();
    return data;
  }
);

// Initial state
const initialState = {};

// Actual Slice
export const UserPreferencesSlice = createSlice({
  name: 'UserPreferences',
  initialState,
  reducers: {
    setUserPreferencesState(state, action) {
      state = action.payload;
    },
    setSidebarCollapseState(state, action) {
      state.sidebarCollapse = action.payload;
    },
    setRightbarCollapseState(state, action) {
      state.rightbarCollapse = action.payload;
    },
    setFavouriteSelectedTabState(state, action) {
      state.favouriteSelectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUserPreferencesAsync.fulfilled, (state, action) => {
      const {
        calendar_preferences,
        favourite_selected_tab,
        rightbar_collapse,
        search_config,
        sidebar_collapse,
      } = action.payload.user_preferences;

      state.calendarPreferences = calendar_preferences;
      state.favouriteSelectedTab = favourite_selected_tab;
      state.rightbarCollapse = rightbar_collapse;
      state.searchConfig = search_config;
      state.sidebarCollapse = sidebar_collapse;
      state.tokkoMessengerURL = action.payload['tokko-messenger-url'];
    });
  },
});

export const {
  setUserPreferencesState,
  setSidebarCollapseState,
  setRightbarCollapseState,
  setFavouriteSelectedTabState,
} = UserPreferencesSlice.actions;

export const selectUserPreferencesState = (state) => state.UserPreferencesSlice;

export const selectTokkoMessengerURL = (state) =>
  state.UserPreferencesSlice.tokkoMessengerURL;

export default UserPreferencesSlice.reducer;
