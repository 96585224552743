/* eslint-disable import/prefer-default-export */

export const initialState = {
  scroll: false,
  exludeDate: false,
  hasUnsavedChanges: false,
  loading: false,
  showModal: false,
  edit: {
    editPanel: false,
    editItem: false,
    editLoader: false,
    dateInstance: false,
    done: false,
    active: true,
  },
  endTimeSelected: false,
  dragTime: { start: '', end: '' },
  description: {
    active: false,
    text: '',
  },
  errors: {},
  types: {
    typeSelected: undefined,
    loading: false,
    typesError: undefined,
    typesData: [],
  },
  reports: {
    bodyTemplate: '[[reporte]]',
    needReport: false,
    text: '',
    sendReportToContact: false,
    reportsTab: false,
  },
  event: [
    {
      title: '',
      start: '',
      end: '',
    },
  ],
  repeat: {
    itemsDropDownRepeatEvent: [],
    dropDownRepeatEventIndex: 0,
    valueDropDownRepeatEvent: '',
    repeatItems: [],
    valueItems: { key: 'día', text: 'day' },
    numberRepeat: 1,
    isCustomRepeat: false,
    monthItems: [],
    valueMonth: '',
    monthDayPos: false,
    monthDayCode: false,
    valueMonthIndex: false,
    endEvent: { nunca: true, el: false },
    endDatePicker: false,
    repeatText: '',
    days: [
      { day: 'D', active: false, text: 'dom', code: 'SU' },
      { day: 'L', active: false, text: 'lun', code: 'MO' },
      { day: 'M', active: false, text: 'mar', code: 'TU' },
      { day: 'M', active: false, text: 'miérc', code: 'WE' },
      { day: 'J', active: false, text: 'jue', code: 'TH' },
      { day: 'V', active: false, text: 'vier', code: 'FR' },
      { day: 'S', active: false, text: 'sáb', code: 'SA' },
    ],
    repeatItemsSingular: [
      { key: 'día', text: 'day' },
      { key: 'sem', text: 'week' },
      { key: 'mes', text: 'month' },
      { key: 'año', text: 'year' },
    ],
    repeatItemsPlural: [
      { key: 'día', text: 'days' },
      { key: 'sem', text: 'weeks' },
      { key: 'mes', text: 'months' },
      { key: 'año', text: 'years' },
    ],
  },
  notifications: {
    contactEmailBefore: false,
    agentNotioficationBefore: false,
    agentEmailBefore: false,
    agentNotificationAfter: false,
    agentEmailAfter: false,
  },
  agents: {
    agentsData: [],
    agentsSelected: [],
    loading: false,
    error: undefined,
  },
  properties: {
    propertiesData: [],
    propertiesSelected: [],
    loading: false,
    error: undefined,
  },
  contacts: {
    contactsData: [],
    contactsSelected: [],
    loading: false,
    error: undefined,
  },
};
