/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import PropertyConfiguratorService from '@/services/PropertyConfiguratorService';

const handler = (name, asyncFunction) =>
  createAsyncThunk(`propertyConfigurator/${name}`, async (arg) => {
    const data = await asyncFunction(arg);
    return data;
  });
export const getPropertiesTypes = handler(
  'getPropertiesTypes',
  PropertyConfiguratorService.getPropertiesTypes
);
export const getAttributesFromPropertiesTypes = handler(
  'getAttributesFromPropertyType',
  PropertyConfiguratorService.getAttributesFromPropertyType
);
export const getExtraAttributesFromPropertyType = handler(
  'getExtraAttributesFromPropertyType',
  PropertyConfiguratorService.getExtraAttributesFromPropertyType
);
export const saveNumericAttribute = handler(
  'saveNumericAttribute',
  PropertyConfiguratorService.saveNumericAttribute
);
export const getAttributesToSearchResult = handler(
  'getAttributesToSearchResult',
  PropertyConfiguratorService.getAttributesToSearchResult
);
export const getSearchResultAttributes = handler(
  'getSearchResultAttributes',
  PropertyConfiguratorService.getSearchResultAttributes
);
export const getAllTagsGroup = handler(
  'getAllTagsGroup',
  PropertyConfiguratorService.getAllTagsGroup
);
export const getAllowedTagGroupsByProperty = handler(
  'getAllowedTagGroupsByProperty',
  PropertyConfiguratorService.getAllowedTagGroupsByProperty
);
export const savePropertyTypeEdition = handler(
  'savePropertyTypeEdition',
  PropertyConfiguratorService.savePropertyTypeEdition
);
export const saveAttributeEdition = handler(
  'saveAttributeEdition',
  PropertyConfiguratorService.saveAttributeEdition
);
export const saveExtraAttribute = handler(
  'saveExtraAttribute',
  PropertyConfiguratorService.saveExtraAttribute
);
export const saveSearchResultAttribute = handler(
  'saveSearchResultAttribute',
  PropertyConfiguratorService.saveSearchResultAttribute
);
export const deleteExtraAttribute = handler(
  'deleteExtraAttribute',
  PropertyConfiguratorService.deleteExtraAttribute
);
export const saveTagGroupActivation = handler(
  'saveTagGroupActivation',
  PropertyConfiguratorService.saveTagGroupActivation
);
export const saveTagGroupDeactivation = handler(
  'saveTagGroupDeactivation',
  PropertyConfiguratorService.saveTagGroupDeactivation
);
export const saveNewTagGroup = handler(
  'saveNewTagGroup',
  PropertyConfiguratorService.saveNewTagGroup
);
