import ApiService from '@/services/ApiService';

class PropertyConfiguratorService {
  static async getPropertiesTypes() {
    const composedURL = `/api3/properties/types`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getAttributesFromPropertyType(type_id) {
    const composedURL = `/api3/properties/get_custom_property_types?type_id=${type_id}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getExtraAttributesFromPropertyType(type_id) {
    const composedURL = `/api3/properties/get_property_types_and_tags?type=${type_id}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getAttributesToSearchResult() {
    const composedURL = `/api3/properties/get_search_attr`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getSearchResultAttributes() {
    const composedURL = `/api3/company/get_properties_search_attributes`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getAllTagsGroup() {
    const composedURL = `/api3/properties/get_allowed_tag_groups_options?property_type_id=2`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getAllowedTagGroupsByProperty(type_id) {
    const composedURL = `/api3/properties/get_allowed_tag_groups?type_id=${type_id}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async savePropertyTypeEdition(requestData) {
    const composedURL = `/api3/company/company_properties_tags_and_types`;
    const response = await ApiService.post(composedURL, requestData);
    return response;
  }

  static async saveAttributeEdition({ requestData, id }) {
    const composedURL = `/api3/properties/edit_custom_prop/${id}`;
    const response = await ApiService.post(composedURL, requestData);
    return response;
  }

  static async saveNumericAttribute(requestData) {
    const composedURL = `/api3/company/company_properties_set_tags`;
    const response = await ApiService.post(composedURL, requestData);
    return response;
  }

  static async saveExtraAttribute(requestData) {
    const composedURL = `/api3/properties/set_custom_attr`;
    const response = await ApiService.post(composedURL, requestData);
    return response;
  }

  static async saveSearchResultAttribute(requestData) {
    const composedURL = `/api3/company/post_search_attributes`;
    const response = await ApiService.post(composedURL, requestData);
    return response;
  }

  static async deleteExtraAttribute(requestData) {
    const composedURL = `/api3/properties/delete_property_types_and_tags`;
    const response = await ApiService.post(composedURL, requestData);
    return response;
  }

  static async saveTagGroupActivation(requestData) {
    const composedURL = `/api3/properties/add_allowed_tag_groups`;
    const response = await ApiService.post(composedURL, requestData);
    return response;
  }

  static async saveTagGroupDeactivation(requestData) {
    const composedURL = `/api3/properties/delete_allowed_tag_groups`;
    const response = await ApiService.post(composedURL, requestData);
    return response;
  }

  static async saveNewTagGroup(requestData) {
    const composedURL = `/api3/properties/create_new_property_tag_group`;
    const response = await ApiService.post(composedURL, requestData);
    return response;
  }
}
export default PropertyConfiguratorService;
