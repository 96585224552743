import ApiService from '@/services/ApiService';

class OpenNaventService {
  static async getInfoForContact(id) {
    const composedURL = `/api3/property/get_info_for_contact?posting_hash=${id}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async contactRealState(data) {
    const composedURL = '/api3/property/contact_real_state';
    const response = await ApiService.post(composedURL, data);
    return response;
  }
}

export default OpenNaventService;
