import { getDictionary } from '@/dictionaries/dictionaries';
import store from '@/store/store';

export default function Trans(file, keyJson, value) {
  let lang = 'en-US';
  if (process.env.NODE_ENV !== 'test') {
    lang =
      store.getState().UserSlice.data.user_language ||
      store.getState().UserSlice.data.company_language;
  }
  let translations;
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test' ||
    lang === undefined
  ) {
    translations = file;
  } else {
    translations = getDictionary(lang);
  }

  const getString = () => {
    let valueString;
    if (keyJson.includes('.')) {
      const keysJson = keyJson.split('.');
      valueString = translations;
      keysJson.forEach((key) => {
        if (valueString !== undefined) {
          valueString = valueString[key];
        }
      });
    } else {
      valueString = translations[keyJson];
    }

    if (valueString !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((val, i) => {
          valueString = valueString.replace(`\${value${i}}`, val);
        });
      } else if (value) {
        valueString = valueString.replace(`\${value}`, value);
      }
    }

    return valueString;
  };

  const valueString = getString();

  if (valueString) {
    return valueString;
  }

  translations = file;
  return getString();
}
