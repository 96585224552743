import { format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';

const lang = 'es';

const formatDate = {
  es: "eeee dd 'de' MMMM",
  en: 'eeee MMMM eo',
};

export const fechaEnLetras = (date) =>
  format(date, formatDate[lang], {
    locale: lang === 'en' ? enUS : es,
  });

export function filterDuplicateElements(response, itemsSelected) {
  const filteredArray = response.filter(
    (item) => !itemsSelected.some((selectedItem) => selectedItem.id === item.id)
  );
  return filteredArray.length ? filteredArray : [{ noData: 'NoResultsFound' }];
}

export const transformKeysToLower = (obj) => {
  const objeto = Object.keys(obj).reduce((newObj, key) => {
    let newKey = key.toLowerCase();

    if (newKey === 'byday') {
      newKey = 'byweekday';
    }

    newObj[newKey] = obj[key];
    return newObj;
  }, {});

  objeto.interval = Number(objeto.interval) || 1;

  if (objeto.bysetpos) {
    objeto.bysetpos = Number(objeto.bysetpos);
  }

  return objeto;
};
