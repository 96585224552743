/* eslint-disable import/prefer-default-export */
import translationsEnUS from './en-US/translations.json';
import translationsEsAR from './es-AR/translations.json';
import translationsEsEC from './es-EC/translations.json';
import translationsEsMX from './es-MX/translations.json';
import translationsEsPE from './es-PE/translations.json';
import translationsEsUY from './es-UY/translations.json';

export const getDictionary = (lang) => {
  const dictionaries = {
    'en-US': () => translationsEnUS,
    'es-AR': () => translationsEsAR,
    'es-EC': () => translationsEsEC,
    'es-MX': () => translationsEsMX,
    'es-PE': () => translationsEsPE,
    'es-UY': () => translationsEsUY,
  };
  return dictionaries[lang]();
};
