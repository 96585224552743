import { Box, Modal, Text } from '@oneloop/jopijs';
import styles from './styles.module.scss';
import ImageNext from '../shared/ImageNext';
import box from './assets/box.svg';
import tokko from './assets/tokko.svg';
import translations from './ErrorComponent.trans.json';
import Trans from '@/components/shared/Trans';
import { tokkoModalActions } from '../TokkoActions/tokkoActions';

export default function ErrorComponent() {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  const handleAction = () => {
    window.location.reload();
    tokkoModalActions({ msg: 'closeModal' }, tokkoBrokerHost);
    tokkoModalActions('closeModal', tokkoBrokerHost);
  };

  return (
    <Modal modalBG blockScroll width="900px" closeModal={handleAction}>
      <Modal.Header closeIcon={handleAction} text="" variant={['start', 'l']} />

      <Modal.Body>
        <Box className={styles.container}>
          <Box className={styles.textCont}>
            <ImageNext alt="logo" src={tokko} unoptimized />

            <Text>Oops !!!</Text>

            <Text>{Trans(translations, 'SorrySomethingWentWrong')}.</Text>

            <Box className={styles.btn} onClick={handleAction}>
              {Trans(translations, 'Close')}
            </Box>
          </Box>

          <ImageNext alt="logo" src={box} unoptimized />
        </Box>
      </Modal.Body>
    </Modal>
  );
}
