/* eslint-disable prefer-destructuring */
import { createSlice } from '@reduxjs/toolkit';
import { addMinutes, format, isBefore, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { initialState } from './initialState';
import {
  fechaEnLetras,
  filterDuplicateElements,
  transformKeysToLower,
} from './utils';
import {
  createEvent,
  deleteEvent,
  doneOneInstance,
  editEvent,
  editOneInstance,
  eventDone,
  excludeDates,
  getAgents,
  getContact,
  getContacts,
  getEvent,
  getEvents,
  getProperties,
  getProperty,
  getTypes,
} from './services';

export const CalendarEventsSlice = createSlice({
  name: 'Calendar-events',
  initialState,
  reducers: {
    setCurrentEvent(state) {
      state.event = [state.event[0]];
    },

    resetErrors(state) {
      state.errors = {};
    },

    setScroll(state, action) {
      state.scroll = action.payload;
    },

    setUser(state, action) {
      state.user = action.payload;
    },

    toggleEditModal(state, action) {
      state.showModal = true;
      state.edit.editItem = action.payload.id;
      state.edit.editPanel = true;

      if (action.payload?.date) {
        state.edit.dateInstance = action.payload.date;
        state.exludeDate = parseISO(action.payload.date);
      }
    },

    fieldsValidation(state, action) {
      // eslint-disable-next-line no-return-assign
      const handleErrors = (err) =>
        (state.errors = { ...state.errors, ...err });

      const deleteErrors = (key) => delete state.errors[key];

      const { event, contacts, properties, agents, types } = state;
      const { typeSelected } = types;
      const { contactsSelected } = contacts;
      const { propertiesSelected } = properties;
      const { agentsSelected } = agents;

      const FIELDS = {
        title: () => {
          if (!event[0].title.trim()) {
            handleErrors({ title: 'el nombre del evento' });
          } else {
            deleteErrors('title');
          }
        },
        contacts: () => {
          if (
            typeSelected?.contacts_required &&
            !(contactsSelected.length >= typeSelected.contacts_required)
          ) {
            handleErrors({
              contacts: `${typeSelected.contacts_required} contactos`,
            });
          } else {
            deleteErrors('contacts');
          }
        },

        properties: () => {
          if (
            typeSelected?.properties_required &&
            !(propertiesSelected.length >= typeSelected.properties_required)
          ) {
            handleErrors({
              properties: `${typeSelected.properties_required} propiedades`,
            });
          } else {
            deleteErrors('properties');
          }
        },

        agents: () => {
          if (
            typeSelected?.users_required &&
            !(agentsSelected.length >= typeSelected.users_required)
          ) {
            handleErrors({
              agents: `${typeSelected.users_required} agentes`,
            });
          } else if (!agentsSelected.length) {
            handleErrors({ agents: '1 agente como mínimo' });
          } else {
            deleteErrors('agents');
          }
        },

        endDate: () => {
          if (isBefore(event[0].end, event[0].start)) {
            handleErrors({ endDate: 'rango de horario no válido' });
          } else {
            deleteErrors('endDate');
          }
        },

        needReport: () => {
          if (
            state.edit.editPanel &&
            !state.reports.text &&
            state.reports.needReport
          ) {
            handleErrors({
              needReport:
                'completa el reporte de finalización para finalizar el evento',
            });
          } else {
            deleteErrors('needReport');
          }
        },
      };

      if (action.payload) {
        FIELDS[action.payload]();
      } else {
        state.errors = {};

        Object.keys(FIELDS).forEach((check) => FIELDS[check]());
      }
    },

    changeDragTime(state, action) {
      const { start, end } = action.payload;

      state.dragTime = { start, end };
      state.event[0] = { ...state.event[0], ...action.payload };
    },

    changeTypeSelected(state, action) {
      const { title } = state.event[0];

      if (action.payload) {
        const {
          notify_contact_start,
          notify_agent_start,
          mail_agent_start,
          notify_agent_end,
          mail_agent_end,
          color,
          name,
          body_template,
          need_report,
          text,
          send_report_to_contact,
        } = action.payload;

        // Settings reports
        state.reports.bodyTemplate = body_template;
        state.reports.needReport = need_report;
        state.reports.text = text;
        state.reports.sendReportToContact = send_report_to_contact;

        // Notifications
        state.types.typeSelected = action.payload;
        state.notifications.contactEmailBefore = notify_contact_start;
        state.notifications.agentNotioficationBefore = notify_agent_start;
        state.notifications.agentEmailBefore = mail_agent_start;
        state.notifications.agentNotificationAfter = notify_agent_end;
        state.notifications.agentEmailAfter = mail_agent_end;

        CalendarEventsSlice.caseReducers.changeEvent(state, {
          type: 'Calendar-events/changeEvent',
          payload: {
            backgroundColor: color,
            borderColor: color,
            title: title === '' ? name : title,
          },
        });
      } else {
        state.types.typeSelected = undefined;
        Object.assign(state.notifications, initialState.notifications);

        CalendarEventsSlice.caseReducers.changeEvent(state, {
          type: 'Calendar-events/changeEvent',
          payload: {
            backgroundColor: '#6f838d',
            borderColor: '#6f838d',
            title,
          },
        });
      }
    },

    changerValue(state, action) {
      const { block, key, value } = action.payload;

      state[block][key] = value;

      if (state.edit.editPanel && key === 'text') {
        state.hasUnsavedChanges = true;
      }
    },

    addItem(state, action) {
      const { block, key, value } = action.payload;

      state[block][key].push(value);

      if (Object.keys(state.errors).includes(block)) {
        CalendarEventsSlice.caseReducers.fieldsValidation(state, {
          type: 'Calendar-events/fieldsValidation',
          payload: block,
        });
      }
    },

    removeItem(state, action) {
      const { block, key, value } = action.payload;

      const newArray = state[block][key].filter((item) => item.id !== value);

      state[block][key] = newArray;
    },

    changeValueDropDownRepeatEvent(state, action) {
      const { index, value } = action.payload;

      state.repeat.valueDropDownRepeatEvent = value;
      state.repeat.dropDownRepeatEventIndex = index;
    },

    createItemsDropDownRepeatEvent(state, action) {
      const { itemsDropDownRepeatEvent, dropDownRepeatEventIndex } =
        state.repeat;

      state.repeat.itemsDropDownRepeatEvent = [
        'No se repite',
        'Todos los días',
        `Todos los ${format(action.payload, 'EEEE', {
          locale: es,
        })}`,
        `Anualmente el ${format(action.payload, "dd 'de' MMMM", {
          locale: es,
        })}`,
      ];

      state.repeat.valueDropDownRepeatEvent =
        itemsDropDownRepeatEvent[dropDownRepeatEventIndex];
    },

    changeEndEvent(state, action) {
      state.repeat.endEvent = {
        nunca: action.payload === 'nunca',
        el: action.payload !== 'nunca',
      };
    },

    createMonthArray(state, action) {
      const numeroSemana = Math.ceil(action.payload.getDate() / 7);

      const dayWeek = {
        1: 'primer',
        2: 'segundo',
        3: 'tercer',
        4: 'cuarto',
        5: 'último',
      };

      state.repeat.monthItems = [
        `Cada mes el día ${format(action.payload, 'dd')}`,
        `Cada mes el ${dayWeek[numeroSemana]} ${format(action.payload, 'EEEE', {
          locale: es,
        })}`,
      ];

      const filterCode = state.repeat.days.filter(
        (day) =>
          day.text ===
          format(action.payload, 'EEEE', { locale: es }).substring(0, 3)
      );

      state.repeat.monthDayPos = numeroSemana === 5 ? -1 : numeroSemana;

      state.repeat.monthDayCode = filterCode[0]?.code;

      state.repeat.valueMonth =
        state.repeat.monthItems[state.repeat.valueMonthIndex || 0];
    },

    changeMonthValue(state, action) {
      state.repeat.valueMonth = state.repeat.monthItems[action.payload];
      state.repeat.valueMonthIndex = action.payload;
    },

    changeNumberRepeat(state, action) {
      const {
        repeatItems,
        valueItems,
        repeatItemsPlural,
        repeatItemsSingular,
      } = state.repeat;

      const index = repeatItems.findIndex((obj) => obj.key === valueItems.key);

      state.repeat.numberRepeat = action.payload;

      state.repeat.repeatItems =
        state.repeat.numberRepeat > 1 ? repeatItemsPlural : repeatItemsSingular;

      state.repeat.valueItems =
        state.repeat.numberRepeat > 1
          ? repeatItemsPlural[index]
          : repeatItemsSingular[index];
    },

    toggleDescription(state) {
      state.description.active = !state.description.active;
      if (!state.description.active) {
        state.description.text = '';
      }
    },

    handleEndTimeSelected(state, action) {
      state.endTimeSelected = action.payload;
    },
    changeEvent(state, action) {
      state.event[0] = { ...state.event[0], ...action.payload };

      if (state.event[0].className) {
        delete state.event[0].className;
      }

      const handleValidation = (key) =>
        CalendarEventsSlice.caseReducers.fieldsValidation(state, {
          type: 'Calendar-events/fieldsValidation',
          payload: key,
        });

      if (Object.keys(action.payload).includes('title')) {
        handleValidation('title');
      }

      if (Object.keys(action.payload).includes('end')) {
        handleValidation('endDate');
      }

      if (!action.payload.title && state.event[0].start) {
        CalendarEventsSlice.caseReducers.createMonthArray(state, {
          type: 'Calendar-events/createMonthArray',
          payload: state.event[0].start,
        });

        if (isBefore(state.repeat.endDatePicker, state.event[0].start)) {
          state.repeat.endDatePicker = state.event[0].start;
        }

        CalendarEventsSlice.caseReducers.createItemsDropDownRepeatEvent(state, {
          type: 'Calendar-events/createItemsDropDownRepeatEvent',
          payload: state.event[0].start,
        });

        if (state.repeat.isCustomRepeat) {
          CalendarEventsSlice.caseReducers.setRepeat(state, {
            type: 'Calendar-events/setRepeat',
            payload: state.repeat.dropDownRepeatEventIndex,
          });
        }
      }
    },

    changeInitialTime(state, action) {
      state.event[0] = {
        ...state.event[0],
        start: action.payload,
        end: addMinutes(action.payload, state.endTimeSelected || 60),
      };
    },

    setRepeat(state, action) {
      state.repeat.isCustomRepeat = true;

      const {
        valueItems,
        numberRepeat,
        valueMonth,
        endEvent,
        endDatePicker,
        days,
        itemsDropDownRepeatEvent,
      } = state.repeat;

      const SemDays = () => {
        let text = 'el ';
        let all = 0;

        // eslint-disable-next-line no-return-assign
        days.forEach((day) => day.active && ((text += `${day.text}, `), all++));

        return all === 7
          ? 'todos los días'
          : text.substring(0, text.length - 2);
      };

      const EACH = {
        mes: valueMonth.substring(9, valueMonth.length),
        año: `el ${fechaEnLetras(state.event[0].start)}`,
        día: false,
        sem: SemDays(),
      };

      const obj = {
        each: { num: numberRepeat, text: valueItems.text },
        eachDays: EACH[valueItems.key],
        endRepeat: endEvent.el && fechaEnLetras(endDatePicker),
      };

      const { each, eachDays, endRepeat } = obj;

      const OPTIONS = {
        0: () => {
          state.repeat.isCustomRepeat = false;
          state.repeat.valueDropDownRepeatEvent =
            itemsDropDownRepeatEvent[action.payload];
          state.repeat.dropDownRepeatEventIndex = action.payload;
        },
        1: () => {
          state.repeat.valueDropDownRepeatEvent =
            itemsDropDownRepeatEvent[action.payload];
          state.repeat.dropDownRepeatEventIndex = action.payload;
        },
        2: () => {
          state.repeat.valueDropDownRepeatEvent =
            itemsDropDownRepeatEvent[action.payload];
          state.repeat.dropDownRepeatEventIndex = action.payload;

          const index = state.event[0].start.getDay();

          state.repeat.days = days.map((day) => ({ ...day, active: false }));
          state.repeat.days[index] = { ...days[index], active: true };
        },
        3: () => {
          state.repeat.valueDropDownRepeatEvent =
            itemsDropDownRepeatEvent[action.payload];
          state.repeat.dropDownRepeatEventIndex = action.payload;
        },
        4: () => {
          state.repeat.repeatText = `Cada ${each.num > 1 ? each.num : ''} ${
            each.text
          } ${eachDays || ''}${endRepeat ? `, hasta el: ${endRepeat}` : ''}`;
          state.repeat.itemsDropDownRepeatEvent[4] = state.repeat.repeatText;
          state.repeat.valueDropDownRepeatEvent =
            state.repeat.itemsDropDownRepeatEvent[4];
        },
      };

      OPTIONS[action.payload]();
    },

    toggleModal(state) {
      state.showModal = !state.showModal;
      state.edit.editPanel = false;

      if (!state.showModal) {
        Object.assign(state, initialState);
      }
    },
  },

  extraReducers: (builder) => {
    builder // getTypes
      .addCase(getTypes.pending, (state) => {
        state.types.loading = true;
      })
      .addCase(getTypes.fulfilled, (state, action) => {
        state.types.loading = false;
        state.types.typesData = action.payload.event_types;
      });

    builder // getAgents
      .addCase(getAgents.pending, (state) => {
        state.agents.loading = true;
      })
      .addCase(getAgents.fulfilled, (state, action) => {
        state.agents.loading = false;

        state.agents.agentsData = filterDuplicateElements(
          action.payload.users,
          state.agents.agentsSelected
        );
      });

    builder // getProperties
      .addCase(getProperties.pending, (state) => {
        state.properties.loading = true;
      })
      .addCase(getProperties.fulfilled, (state, action) => {
        state.properties.loading = false;

        state.properties.propertiesData = filterDuplicateElements(
          action.payload.properties,
          state.properties.propertiesSelected
        );
      });

    builder // getProperty
      .addCase(getProperty.fulfilled, (state, action) => {
        state.properties.propertiesSelected = action.payload.properties;
      });

    builder // getContact
      .addCase(getContact.fulfilled, (state, action) => {
        state.contacts.contactsSelected = action.payload.contacts;
      });

    builder // getContacts
      .addCase(getContacts.pending, (state) => {
        state.contacts.loading = true;
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        state.contacts.loading = false;

        state.contacts.contactsData = filterDuplicateElements(
          action.payload.contacts,
          state.contacts.contactsSelected
        );
      });

    builder // getEvents
      .addCase(getEvents.fulfilled, (state, action) => {
        const convertirHoraMinuto = (numero) => {
          const horas = Math.floor(numero / 60);
          const minutos = numero % 60;

          const horaFormateada = horas.toString().padStart(2, '0');
          const minutoFormateado = minutos.toString().padStart(2, '0');

          return `${horaFormateada}:${minutoFormateado}`;
        };

        const formatUsers = (users) => {
          if (users.length === 1) {
            return users[0].full_name;
          }
          return `${users[0].full_name} +${users.length - 1}`;
        };

        let colors;
        const mapEvents = (events) =>
          events.map((event) => {
            if (event.users.some((u) => u.id === state.user.id)) {
              colors = {
                backgroundColor: event.type?.color || '#6f838d',
                borderColor: event.type?.color || '#6f838d',
              };
            } else {
              colors = {
                backgroundColor: '#fff',
                borderColor: '#485C66',
                textColor: '#485C66',
              };
            }

            const settingsEvent = {
              title: event.users.some((u) => u.id === state.user.id)
                ? event.name
                : formatUsers(event.users),
              ...colors,
              editable: false,
              className: 'notAllowed',
            };

            if (event?.repeat_in) {
              return {
                rrule: event.repeat_in,
                duration: convertirHoraMinuto(event.duration),
                ...settingsEvent,
              };
            }

            return {
              start: event.start,
              end: event.end,
              ...settingsEvent,
            };
          });

        const filterEvents = action.payload.events.filter(
          (event) => event.id !== Number(state.edit.editItem)
        );

        state.event = [state.event[0], ...mapEvents(filterEvents)];
      });

    builder // create Event
      .addCase(createEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEvent.fulfilled, (state) => {
        state.loading = false;
        CalendarEventsSlice.caseReducers.toggleModal(state, {
          type: 'Calendar-events/toggleModal',
        });
      });

    builder // getEvent
      .addCase(getEvent.pending, (state) => {
        state.edit.editLoader = true;
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.edit.editLoader = false;
        const {
          event_data,
          event_type,
          contacts,
          properties,
          users,
          event_settings,
        } = action.payload.event;
        const startDate = parseISO(event_data.date_from);

        state.edit.done = event_data.done;
        state.edit.active = event_data.active;

        state.event[0] = {
          start: startDate,
          end: parseISO(event_data.date_to),
          title: event_data.name,
          backgroundColor: event_type?.color || '#6f838d',
          borderColor: event_type?.color || '#6f838d',
          className: 'notAllowed',
          repeat_in: event_data.repeat_in,
        };

        state.endTimeSelected = event_data.duration;

        CalendarEventsSlice.caseReducers.createItemsDropDownRepeatEvent(state, {
          type: 'Calendar-events/createItemsDropDownRepeatEvent',
          payload: startDate,
        });

        state.types.typeSelected = event_type;

        // Settings reports
        const { body_template, need_report, text, send_report_to_contact } =
          event_settings;

        state.reports.bodyTemplate = body_template;
        state.reports.needReport = need_report;
        state.reports.text = text;
        state.reports.sendReportToContact = send_report_to_contact;

        state.contacts.contactsSelected = contacts;
        state.properties.propertiesSelected = properties;
        state.agents.agentsSelected = users;

        state.description.active = event_data.description !== '';
        state.description.text = event_data.description;

        const {
          mail_agent_start,
          notify_agent_start,
          notify_contact_start,
          notify_agent_end,
          mail_agent_end,
        } = event_settings;

        state.notifications.contactEmailBefore = notify_contact_start;
        state.notifications.agentNotioficationBefore = notify_agent_start;
        state.notifications.agentEmailBefore = mail_agent_start;
        state.notifications.agentNotificationAfter = notify_agent_end;
        state.notifications.agentEmailAfter = mail_agent_end;

        // set recurrence
        if (event_data?.repeat_in) {
          const { days } = state.repeat;
          const rrule = transformKeysToLower(event_data.repeat_in);
          const freq = {
            DAILY: 0,
            WEEKLY: 1,
            MONTHLY: 2,
            YEARLY: 3,
          };

          CalendarEventsSlice.caseReducers.changeNumberRepeat(state, {
            type: 'Calendar-events/changeNumberRepeat',
            payload: rrule.interval,
          });

          state.repeat.valueItems = state.repeat.repeatItems[freq[rrule.freq]];

          if (rrule.freq === 'WEEKLY') {
            state.repeat.days = days.map((a) =>
              rrule.byweekday.includes(a.code) ? { ...a, active: true } : a
            );
          }

          if (rrule.freq === 'MONTHLY') {
            CalendarEventsSlice.caseReducers.createMonthArray(state, {
              type: 'Calendar-events/createMonthArray',
              payload: startDate,
            });

            if (rrule?.byweekday) {
              state.repeat.valueMonth = state.repeat.monthItems[1];
            }
          }

          if (rrule?.until) {
            state.repeat.endEvent = { nunca: false, el: true };
            state.repeat.endDatePicker = parseISO(rrule.until);
          }

          CalendarEventsSlice.caseReducers.setRepeat(state, {
            type: 'Calendar-events/setRepeat',
            payload: 4,
          });

          state.repeat.dropDownRepeatEventIndex = 4;
        } else {
          state.repeat.dropDownRepeatEventIndex = 0;
        }
      });

    builder // delete event
      .addCase(deleteEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteEvent.fulfilled, (state) => {
        state.loading = false;
        CalendarEventsSlice.caseReducers.toggleModal(state, {
          type: 'Calendar-events/toggleModal',
        });
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.loading = false;
        state.myError = action.error;
      });

    builder // exclude Dates
      .addCase(excludeDates.pending, (state) => {
        state.loading = true;
      })
      .addCase(excludeDates.fulfilled, (state) => {
        state.loading = false;
        CalendarEventsSlice.caseReducers.toggleModal(state, {
          type: 'Calendar-events/toggleModal',
        });
      });

    builder // done event
      .addCase(eventDone.pending, (state) => {
        state.loading = true;
      })
      .addCase(eventDone.fulfilled, (state) => {
        state.loading = false;
        CalendarEventsSlice.caseReducers.toggleModal(state, {
          type: 'Calendar-events/toggleModal',
        });
      })
      .addCase(eventDone.rejected, (state, action) => {
        state.loading = false;
        state.myError = action.error;
      });

    builder // done one instance
      .addCase(doneOneInstance.pending, (state) => {
        state.loading = true;
      })
      .addCase(doneOneInstance.fulfilled, (state) => {
        state.loading = false;
        CalendarEventsSlice.caseReducers.toggleModal(state, {
          type: 'Calendar-events/toggleModal',
        });
      });

    builder // edit event
      .addCase(editEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(editEvent.fulfilled, (state) => {
        state.loading = false;

        CalendarEventsSlice.caseReducers.toggleModal(state, {
          type: 'Calendar-events/toggleModal',
        });
      })
      .addCase(editEvent.rejected, (state, action) => {
        state.loading = false;
        state.myError = action.error;
      });

    builder // edit one instance
      .addCase(editOneInstance.pending, (state) => {
        state.loading = true;
      })
      .addCase(editOneInstance.fulfilled, (state, action) => {
        state.loading = false;
        if (state.edit.editPanel) {
          CalendarEventsSlice.caseReducers.toggleModal(state, {
            type: 'Calendar-events/toggleModal',
          });
        } else {
          state.edit.dateInstance = false;
          state.edit.editPanel = true;
          state.edit.editItem = action.payload.id;
        }
      });
  },
});

export const {
  resetErrors,
  setScroll,
  toggleModal,
  changeEvent,
  handleEndTimeSelected,
  changeInitialTime,
  setRepeat,
  toggleDescription,
  changeNumberRepeat,
  createMonthArray,
  changeMonthValue,
  changeEndEvent,
  createItemsDropDownRepeatEvent,
  changerValue,
  changeTypeSelected,
  addItem,
  removeItem,
  changeDragTime,
  fieldsValidation,
  setCurrentEvent,
  toggleEditModal,
  setUser,
} = CalendarEventsSlice.actions;

export default CalendarEventsSlice.reducer;
