import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  text: null,
};

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationText(state, action) {
      state.text = action.payload;
    },
  },
});

export const { setNotificationText } = NotificationSlice.actions;

export default NotificationSlice.reducer;
