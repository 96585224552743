import { createAsyncThunk } from '@reduxjs/toolkit';
import FavoritesService from '@/services/FavoritesService';
import PropertiesService from '@/services/PropertiesService';

const handler = (name, asyncFunction) =>
  createAsyncThunk(`Favorites/${name}`, async (arg) => {
    const data = await asyncFunction(arg);
    return data;
  });

export const getFavoritesSearchs = handler(
  'getFavoritesSearchs',
  FavoritesService.getFavSearchs
);

export const getFavoritesProperties = handler(
  'getFavoritesProperties',
  FavoritesService.getFavProperties
);

export const getFavoritesDevelopments = handler(
  'getFavoritesDevelopments',
  FavoritesService.getFavDevelopments
);

export const getFavoritesContacts = handler(
  'getFavoritesContacts',
  FavoritesService.getFavContacts
);

export const deleteFavSearch = handler(
  'deleteFavSearch',
  FavoritesService.deleteFavSearch
);

export const deleteFavProperty = handler(
  'deleteFavProperty',
  FavoritesService.deleteFavProperties
);

export const deleteFavDevelopment = handler(
  'deleteFavDevelopment',
  FavoritesService.deleteFavDevelopments
);

export const deleteFavContact = handler(
  'deleteFavContact',
  FavoritesService.deleteFavContacts
);

export const getPagesForExportPdf = handler(
  'getPagesForExportPdf',
  PropertiesService.getPagesForExportPdf
);

export const exportToXls = handler(
  'exportToXls',
  PropertiesService.exportToXls
);
