import ApiService from '@/services/ApiService';

class UserService {
  static async getPermissions() {
    const composedURL = `/api3/users/permissions`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getUserMeData() {
    const composedURL = `/api3/users/me`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getConfigSetting() {
    const composedURL = `/api3/users/config`;
    const response = await ApiService.get(composedURL);
    localStorage.setItem('tokkoChatHost', response['tokko-messenger-url']);
    return response;
  }

  static async getBadgesCounters() {
    const composedURL = `/api3/users/badges`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async postConfigSetting(requestData) {
    const composedURL = `/api3/users/config`;
    const response = await ApiService.post(composedURL, requestData);
    return response;
  }

  static async postCurrentFavoriteTab(requestData) {
    const composedURL = `/api3/users/set_current_favourite_tab`;
    const response = await ApiService.post(composedURL, requestData);
    return response;
  }

  static async postSearchParameters(requestData) {
    const composedURL = `/api3/users/search_parameters`;
    const response = await ApiService.post(composedURL, requestData);
    return response;
  }

  static async getCompanyConfig() {
    const composedURL = `/api3/company/config`;
    const response = await ApiService.get(composedURL);
    return response;
  }
}

export default UserService;
