import { useEffect } from 'react';

export default function useOnClickOutside(ref, state, setState) {
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (state && ref.current && !ref.current.contains(e.target)) {
        setState(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [state]);
}

export function isJSON(text) {
  const rxOne = /^[\],:{}\s]*$/;
  const rxTwo = /\\(?:["\\/bfnrt]|u[0-9a-fA-F]{4})/g;
  const rxThree =
    /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?/g;
  const rxFour = /(?:^|:|,)(?:\s*\[)+/g;
  const isJSONResult = (input) =>
    input.length &&
    rxOne.test(
      input.replace(rxTwo, '@').replace(rxThree, ']').replace(rxFour, '')
    );
  return isJSONResult(text);
}

export const darkenColor = (hex, percent) => {
  // Verificar si el valor percent está dentro del rango válido (0-100)
  percent = Math.min(100, Math.max(0, percent));

  // Convertir el color hexadecimal a valores RGB
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // Calcular el nuevo valor RGB con el porcentaje de oscurecimiento
  r = Math.floor(r * (1 - percent / 100));
  g = Math.floor(g * (1 - percent / 100));
  b = Math.floor(b * (1 - percent / 100));

  // Convertir los valores RGB de vuelta a hexadecimal y devolver el nuevo color
  return `#${(r < 16 ? '0' : '') + r.toString(16)}${
    (g < 16 ? '0' : '') + g.toString(16)
  }${(b < 16 ? '0' : '') + b.toString(16)}`;
};

export const lightenColor = (hex, percent) => {
  // Verificar si el valor percent está dentro del rango válido (0-100)
  percent = Math.min(100, Math.max(0, percent));

  // Convertir el color hexadecimal a valores RGB
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // Calcular el nuevo valor RGB con el porcentaje de aclarado
  r = Math.min(255, Math.floor(r + r * (percent / 100)));
  g = Math.min(255, Math.floor(g + g * (percent / 100)));
  b = Math.min(255, Math.floor(b + b * (percent / 100)));

  // Convertir los valores RGB de vuelta a hexadecimal y devolver el nuevo color
  return `#${(r < 16 ? '0' : '') + r.toString(16)}${
    (g < 16 ? '0' : '') + g.toString(16)
  }${(b < 16 ? '0' : '') + b.toString(16)}`;
};

// Funcion para generar la navegacion por teclado en las listas
// La función keyNavegation recibe el evento del teclado y el ref de la lista
// La lista debe tener la clase 'selected' para que se pueda seleccionar el elemento, osea agregar el archivo css con la clase correspondiente
// La función keyNavegation se debe llamar en el evento onKeyDown del input
// La función resetIndexList se utiliza para resetear el index de la lista cuando se necesite hacerlo
let indexList = -1;

export const resetIndexList = () => {
  indexList = -1;
};

export const keyNavegation = (key, ul) => {
  const list = ul.current;
  const elements = ul.current.children;

  if (!list.children.length) return;

  if (indexList >= 0) {
    elements[indexList].classList.remove('selected');
  }

  const ACTIONS = {
    ArrowDown: () => {
      indexList = Math.min(elements.length - 1, indexList + 1);
    },
    ArrowUp: () => {
      indexList = Math.max(0, indexList - 1);
    },
    Enter: () => indexList >= 0 && elements[indexList].click(),
  };

  ACTIONS[key]();

  if (indexList >= 0) {
    const element = elements[indexList];

    const elementTop = element.offsetTop;
    const elementHeight = element.offsetHeight;
    const listHeight = list.clientHeight;

    if (indexList >= 0) {
      list.scrollTop = elementTop + elementHeight - listHeight;
    }

    element.classList.add('selected');
  }
};
