/* eslint-disable import/prefer-default-export */

export const initialState = {
  show: false,
  openedInContactFile: false,
  fromOuterNet: false,
  isDefaultEdited: true,
  preSelectedContacts: [],
  preSelectedProperties: [],
  preSelectedDevelopments: [],
  subject: '',
  language: 'es',
  sendCompanyData: false,
  isSendingEmail: false,
  isPreparingWhatsapp: false,
  tour: {
    presentation: true,
    initial: true,
    cc: true,
    files: true,
  },
  warning: null,
  message: {
    text: '',
    html: '',
  },
  pdfOption: {
    value: null,
    includeMap: false,
  },
  afterSending: {
    isLoading: true,
    data: [],
    selected: null,
  },
  addressees: {
    fetchedSuggestions: null,
    selected: [],
    input: '',
    isLoading: false,
    isCollapsed: false,
    howManyShowCollapsed: 1,
    mustShowOnlyOneSelectedMessage: false,
  },
  cc: {
    fetchedSuggestions: null,
    selected: [],
    show: false,
    isLoading: false,
    input: '',
    isCollapsed: false,
    howManyShowCollapsed: 1,
  },
  whatsapp: {
    app: 'desktop',
    fetchedSuggestions: null,
    selected: [],
    isLoading: false,
    input: '',
    isValidatingInput: false,
    inputWasValidated: false,
    inputIsValid: false,
  },
  related: {
    isLoading: false,
    data: [],
  },
  properties: {
    fetchedSuggestions: null,
    selected: [],
    input: '',
    isLoading: false,
    showStarred: false,
  },
};
