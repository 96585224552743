const initialState = {
  contacts: [],
  properties: [],
  developments: [],
  searches: [],
  listPagesPdf: [],
  conflictsUnifyContacts: [],
  idsRadioConflictContact: [],
  descriptionWarning: '',
  pdfType: 1,
  pdfTypeHover: 0,
  isLoading: false,
};

export default initialState;
