import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Loader } from '@oneloop/jopijs';
import {
  setPermissionsAsync,
  setCompanyConfigAsync,
} from '@/store/slices/AuthSlice';
import { updateUserDataAsync } from '@/store/slices/UserSlice';
import styles from './SettingsReactLoader.module.scss';
import { setUserPreferencesAsync } from '@/store/slices/UserPreferencesSlice';

export default function SettingsReactLoader({ setEnabledToken }) {
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      await Promise.all([
        dispatch(setUserPreferencesAsync()).unwrap(),
        dispatch(setCompanyConfigAsync()).unwrap(),
        dispatch(setPermissionsAsync()).unwrap(),
        dispatch(updateUserDataAsync()).unwrap(),
      ]);

      setEnabledToken(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (typeof window === 'object' && document !== undefined) {
      const routeHostReferrer = document.referrer;
      const routeHostReferrerFix =
        routeHostReferrer.charAt(routeHostReferrer.length - 1) === '/'
          ? routeHostReferrer.substring(0, routeHostReferrer.length - 1)
          : routeHostReferrer;
      const tokkoBrokerHostFinal = !routeHostReferrerFix.includes(
        window.location.origin
      )
        ? routeHostReferrerFix
        : localStorage.getItem('tokkoBrokerHost');
      if (tokkoBrokerHostFinal) {
        localStorage.setItem('tokkoBrokerHost', tokkoBrokerHostFinal);
      }

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const jwtBearer = url.searchParams.get('jwt');

      if (jwtBearer) {
        localStorage.setItem('jwt', jwtBearer);
        params.delete('jwt');
        url.search = params.toString();

        setTimeout(() => {
          window.history.pushState(null, null, url.toString());
        }, 100);
      }

      fetchData();
    }
  }, []);

  return (
    <Box className={styles.bgLoader}>
      <Loader
        variant="spinner"
        style={{
          width: '50px',
          height: '50px',
          backgroundImage: ' conic-gradient(#878C9B ,#FFFFFF)',
        }}
      />
    </Box>
  );
}
